exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-dermabrasion-js": () => import("./../../../src/pages/dermabrasion.js" /* webpackChunkName: "component---src-pages-dermabrasion-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kosmetik-js": () => import("./../../../src/pages/kosmetik.js" /* webpackChunkName: "component---src-pages-kosmetik-js" */),
  "component---src-pages-permanent-make-up-js": () => import("./../../../src/pages/permanent-make-up.js" /* webpackChunkName: "component---src-pages-permanent-make-up-js" */)
}

